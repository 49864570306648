import { Event } from 'features/events/eventsSlice';
import { HubEventPayload, HubRecordingPayload } from 'features/hub/hub';
import { Recording } from 'features/recordings/recordingsSlice';
import { railsToIANA } from 'lib/timezone';
import { DateTime } from 'luxon';

export const mapHubEventToEvent = (hubEvent: HubEventPayload): Event => {
  return {
    id: hubEvent.id.toString(),
    title: hubEvent.title,
    description: hubEvent.description,
    artworkUrl: hubEvent.artwork.url ? hubEvent.artwork.url : null,
    artworkKey: hubEvent.artwork.original_key
      ? hubEvent.artwork.original_key
      : null,
    artworkMode: hubEvent.artwork_mode,
    startsAt: hubEvent.starts_at,
    endsAt: hubEvent.ends_at,
    startedAt: hubEvent.started_at,
    endedAt: hubEvent.ended_at,
    adhoc: hubEvent.adhoc || false,
    timezone: railsToIANA(hubEvent.timezone),
    active: hubEvent.active,
    accesscodeEnabled: hubEvent.accesscode_enabled || false,
    accesscodeLink: null,
    accesscodeLinkType: null,
    accesscodeCopy: null,
    chatEnabled: hubEvent.chat_enabled === null ? true : hubEvent.chat_enabled,
    heartingEnabled:
      hubEvent.hearting_enabled === null ? true : hubEvent.hearting_enabled,
    statsVisible:
      hubEvent.stats_visible === null ? true : hubEvent.stats_visible,
    accessFailed: false,
    broadcasterId: hubEvent.broadcaster.id.toString(),
    legacyUrl: hubEvent.url,
    lastUpdatedAt: hubEvent.updated_at,
    color: hubEvent.color,
    accessLevel: hubEvent.access_level,
    lastHubEventAt: DateTime.now().toSeconds(),
    lastHubEventType: null,
    artwork: hubEvent.media.artwork,
    categoryId: hubEvent.category.id,
    fallback: hubEvent.fallback
  };
};

export const mapHubRecordingToRecording = (
  hubRecording: HubRecordingPayload
): Recording => {
  return {
    accessLevel: hubRecording.access_level,
    artworkKey: hubRecording.artwork_key,
    artworkUrl: null,
    categoryId: hubRecording.category_id?.toString() || null,
    createdAt: hubRecording.created_at,
    description: hubRecording.description || '',
    duration: hubRecording.duration,
    fileFormat: hubRecording.file_format || '',
    id: hubRecording.id.toString(),
    title: hubRecording.title,
    url: hubRecording.url,
    userId: hubRecording.user_id.toString(),
    color: hubRecording.color,
    artwork: hubRecording.media.artwork,
    artworkMode: hubRecording.artwork_mode,
    shouldDisplayOnChannel: hubRecording.should_display_on_channel
  };
};
