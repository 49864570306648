import React, {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { Icon } from 'components/Icons';
import emojiStyles from './styles.module.scss';
import classNames from 'classnames';
import { ThemeContext } from 'features/theme/theme';
import EmojiPicker, {
  PickerProps,
  EmojiStyle,
  Theme
} from 'emoji-picker-react';
import { usePopper } from 'react-popper';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useMediaQuery } from 'react-responsive';

export interface EmojiPickerProps {
  buttonLabel?: string;
  pickerOpened?: (opened: boolean) => void;
}

export type EmojiPickerHandle = {
  closeEmojiPicker: () => void;
};

const EmojiPickerButton: ForwardRefRenderFunction<
  EmojiPickerHandle,
  EmojiPickerProps & PickerProps
> = (props, ref) => {
  const [pickerButton, setPickerButton] = useState<HTMLButtonElement | null>(
    null
  );
  const [pickerEmojii, setPickerEmojii] = useState<HTMLDivElement | null>(null);
  const pickerRef = useRef<HTMLDivElement | null>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { darkMode } = useContext(ThemeContext);
  const { styles, attributes, update, state } = usePopper(
    pickerButton,
    pickerEmojii,
    {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 8,
            rootBoundary: 'document'
          }
        }
      ],
      placement: 'top',
      strategy: 'fixed'
    }
  );
  const emojiiResizeObserverRef = useRef<ResizeObserver | null>(null);
  const isMobileLandscape = useMediaQuery({ query: '(max-height: 470px)' });
  const { isDACreatorApp } = useContext(UserAgentContext);
  const PICKER_HEIGHT = isMobileLandscape || isDACreatorApp ? 220 : 260;
  const REACTION_PICKER_HEIGHT = 50;

  const handleEmojiClickOutside = () => {
    togglePicker(false);
    if (props.pickerOpened) {
      props.pickerOpened(pickerOpen);
    }
  };

  useEffect(() => {
    emojiiResizeObserverRef.current = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      }
    });

    return () => {
      emojiiResizeObserverRef.current?.disconnect;
    };
  }, []);

  useEffect(
    function useResizeObserverOnReactionPicker() {
      if (
        pickerEmojii &&
        pickerEmojii.clientHeight === REACTION_PICKER_HEIGHT
      ) {
        emojiiResizeObserverRef.current?.observe(pickerEmojii);
      }
    },
    [pickerEmojii]
  );

  useEffect(
    function removeTitleLabelOnDA() {
      // https://3.basecamp.com/4293559/buckets/40970082/card_tables/cards/8321237954
      if (pickerEmojii) {
        const reactionPickerBtns = document.querySelectorAll('.epr-btn');
        if (reactionPickerBtns) {
          reactionPickerBtns.forEach(function (btn) {
            btn.removeAttribute('title');
          });
        }
      }
    },
    [pickerEmojii]
  );

  useEffect(
    function updatePickerPositionWhenExpandingReactionPicker() {
      if (pickerEmojii && update && dimensions.height === PICKER_HEIGHT) {
        update();
      }
    },
    [dimensions, update, pickerEmojii, state, PICKER_HEIGHT]
  );

  useEffect(
    function dispatchPickerOpen() {
      if (props.pickerOpened) {
        props.pickerOpened(pickerOpen);
      }
    },
    [pickerOpen, props]
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        closeEmojiPicker() {
          togglePicker(false);
          if (props.pickerOpened) {
            props.pickerOpened(false);
          }
        }
      };
    },
    [props]
  );

  useOnClickOutside(pickerRef, handleEmojiClickOutside);

  return (
    <div className={emojiStyles['emoji-picker']} ref={pickerRef}>
      <button
        ref={setPickerButton}
        onClick={() => togglePicker(!pickerOpen)}
        className={classNames(
          emojiStyles['emoji-picker__button'],
          'icon-button'
        )}
        type="button"
      >
        <div className="tw-flex tw-gap-1 tw-items-center">
          <Icon icon={'emoji'} hidden={true} label={'Choose emoji'} />
          {props.buttonLabel && props.buttonLabel}
        </div>
      </button>
      {pickerOpen && (
        <div
          className="tw-z-50"
          ref={setPickerEmojii}
          style={styles.popper}
          {...attributes.popper}
        >
          <EmojiPicker
            searchPlaceholder={'Search emojis...'}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
            theme={darkMode ? Theme.DARK : Theme.LIGHT}
            previewConfig={{ showPreview: false }}
            className={`tw-shadow-xl tw-rounded-xl tw-overflow-hidden tw-max-w-[320px]`}
            height={PICKER_HEIGHT}
            width={'100%'}
            style={
              {
                '--epr-horizontal-padding': '4px',
                '--epr-emoji-gap': '2px',
                '--epr-header-padding': '8px',
                '--epr-reactions-bg-color': 'var(--color__background)',
                '--epr-emoji-size': '24px',
                '--epr-emoji-fullsize': 'calc(24px + 5px * 2)',
                '--epr-category-padding': '2px',
                '--epr-text-color': 'var(--color__label)',
                '--epr-bg-color': 'var(--color__background)',
                '--epr-search-input-bg-color': 'var(--color__light-background)',
                '--epr-search-input-height': '32px',
                '--epr-search-input-text-color': 'var(--color__label)',
                '--epr-emoji-variation-picker-height': '40px',
                '--epr-emoji-variation-picker-bg-color':
                  'var(--color__background)',
                '--epr-category-label-height': '24px',
                '--epr-category-label-bg-color': 'var(--color__background)'
              } as CSSProperties
            }
            {...props}
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef<EmojiPickerHandle, EmojiPickerProps & PickerProps>(
  EmojiPickerButton
);
