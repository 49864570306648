import { V3ChannelView, Event as apiEvent } from 'api/v3/types/channel_view';
import { createSlice, PayloadAction, original } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { apiV3GetEvent } from 'api/v3/events';
import { V3Event } from 'api/v3/types/event';
import { HubEventPayload } from 'features/hub/hub';
import { railsToIANA } from 'lib/timezone';
import { mapHubEventToEvent } from 'features/hub/stateMappers';
import { setCurrentRecording } from 'features/recordings/recordingsSlice';
import { airbrakeNetworkError } from 'utilities/airbrake';
import { NavigateFunction } from 'react-router-dom';
import { createAlert } from 'features/alert/alertSlice';
import { unloadEvent } from 'thunks/navigationThunks';
import { DateTime } from 'luxon';
import { Media } from 'api/v3/types/media';

export interface Event {
  id: string;
  title: string;
  artworkUrl: string | null;
  artworkKey: string | null;
  startsAt: string | null;
  endsAt: string | null;
  startedAt: string | null;
  endedAt: string | null;
  description: string | null;
  adhoc: boolean;
  timezone: string;
  active: boolean;
  accesscodeEnabled: boolean | null;
  accesscodeLink: string | null;
  accesscodeLinkType: string | null;
  accesscodeCopy: string | null;
  accessFailed: boolean;
  chatEnabled: boolean | null;
  heartingEnabled: boolean | null;
  statsVisible: boolean | null;
  broadcasterId: string;
  legacyUrl: string;
  artworkMode: string;
  lastUpdatedAt: string;
  color: string;
  accessLevel: string;
  lastHubEventAt: number | null;
  lastHubEventType?: string | null;
  artwork: Media;
  fallback: boolean;
  categoryId?: number;
}

export interface EventMap {
  [key: string]: Event;
}

export interface EventsState {
  events: EventMap;
  privateEvents: EventMap;
  isLoading: boolean;
  currentEventId: string | null;
  nativeEventStarted: boolean;
  nativeEventStopped: boolean;
  autoPlayNextEvent: boolean;
  eventMinimised: boolean;
  showInfo: boolean;
}

export const initialEventsState: EventsState = {
  events: {},
  privateEvents: {},
  isLoading: false,
  currentEventId: null,
  nativeEventStarted: false,
  nativeEventStopped: false,
  showInfo: false,
  autoPlayNextEvent: false,
  eventMinimised: false
};

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialEventsState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    addEvents(state, action: PayloadAction<Event[]>) {
      const newEventMap: EventMap = {};
      action.payload.forEach((event) => {
        const lastHubEventAt = state.events[event.id]?.lastHubEventAt;
        if (
          !lastHubEventAt ||
          (lastHubEventAt && lastHubEventAt < DateTime.now().toSeconds() - 30)
        ) {
          newEventMap[event.id] = event;
        }
      });
      state.events = { ...state.events, ...newEventMap };
    },
    addPrivateEvents(state, action: PayloadAction<Event[]>) {
      const newEventMap: EventMap = {};
      action.payload.forEach((event) => {
        newEventMap[event.id] = event;
      });
      state.privateEvents = { ...state.privateEvents, ...newEventMap };
    },
    accessCodeFailure(state, action: PayloadAction<string>) {
      const event = state.privateEvents[action.payload];
      if (!event) return;

      event.accessFailed = true;
    },
    updateEvent(state, { payload: event }: PayloadAction<Partial<Event>>) {
      if (!event.id) {
        return;
      }
      const publicEvent = state.events[event.id];
      const privateEvent = state.privateEvents[event.id];

      if (publicEvent) {
        state.events[event.id] = Object.assign(
          {},
          { ...publicEvent, ...event }
        );
      }
      if (privateEvent) {
        state.events[event.id] = Object.assign(
          {},
          { ...privateEvent, ...event }
        );
      }
    },
    removeEvent(state, { payload: id }: PayloadAction<string>) {
      const orig = original<EventsState>(state);
      if (!orig) {
        return;
      }

      const { [id]: _removedPublicEvent, ...withoutRemovedPublic } =
        orig.events;
      const { [id]: _removedPrivateEvent, ...withoutRemovedPrivate } =
        orig.privateEvents;

      return {
        ...state,
        events: withoutRemovedPublic,
        privateEvents: withoutRemovedPrivate
      };
    },

    setCurrentEventId(state, action: PayloadAction<string | null>) {
      state.currentEventId = action.payload;
    },
    setNativeEventStarted(state, action: PayloadAction<boolean>) {
      state.nativeEventStarted = action.payload;
    },
    setNativeEventStopped(state, action: PayloadAction<boolean>) {
      state.nativeEventStopped = action.payload;
    },
    setEventMinimised(state, action: PayloadAction<boolean>) {
      state.eventMinimised = action.payload;
    },
    setInfoVisible(state, action) {
      state.showInfo = action.payload;
    },
    setAutoplay: (state, { payload: autoplay }: PayloadAction<boolean>) => {
      state.autoPlayNextEvent = autoplay;
    }
  }
});

export const {
  addEvents,
  addPrivateEvents,
  startLoading,
  stopLoading,
  accessCodeFailure,
  updateEvent,
  removeEvent,
  setCurrentEventId,
  setNativeEventStarted,
  setNativeEventStopped,
  setEventMinimised,
  setInfoVisible,
  setAutoplay
} = eventsSlice.actions;

export default eventsSlice.reducer;

export const fetchEvent =
  (
    subdomain: string,
    eventId: string,
    navigate?: NavigateFunction,
    isEmbed?: boolean,
    isCreatorApp?: boolean,
    postMessage?: any
  ): AppThunk =>
  async (dispatch, getState) => {
    const {
      events: { isLoading }
    } = getState();
    if (isLoading) return;
    dispatch(setNativeEventStarted(false));
    dispatch(setNativeEventStopped(false));

    try {
      dispatch(startLoading());
      const response = await apiV3GetEvent(subdomain, eventId);

      dispatch(addEvents([mapApiV3Event(response as V3Event)]));
      dispatch(stopLoading());
    } catch (err) {
      if (err.status == 401) {
        const response = await err.json();
        dispatch(addPrivateEvents([mapApiV3Event(response as V3Event)]));
      } else if (err.status == 404) {
        if (navigate) {
          dispatch(
            navigateErrorEvent(
              navigate,
              isEmbed ? true : false,
              isCreatorApp ? true : false,
              postMessage
            )
          );
        }
        airbrakeNetworkError(err);
      } else {
        airbrakeNetworkError(err);
      }
      dispatch(stopLoading());
    }
  };

export const updateOrFetchEvent =
  (subdomain: string, event: HubEventPayload): AppThunk =>
  async (dispatch, getState) => {
    const {
      events: { privateEvents, events }
    } = getState();
    const publicEvent = events[event.id];
    const privateEvent = privateEvents[event.id];

    if (!publicEvent && !privateEvent) {
      dispatch(fetchEvent(subdomain, event.id.toString()));
    } else {
      dispatch(updateEvent(mapHubEventToEvent(event)));
    }
  };

export const mapApiV3Event = ({
  data: { id, attributes: event }
}: V3Event): Event => {
  return {
    id: id.toString(),
    title: event.title,
    description: event.description,
    artworkUrl: event.artwork_url,
    artworkKey: event.artwork_original_key,
    active: event.active,
    startsAt: event.starts_at,
    endsAt: event.ends_at,
    startedAt: event.started_at,
    endedAt: event.ended_at,
    adhoc: event.adhoc,
    timezone: railsToIANA(event.timezone),
    accesscodeEnabled: event.accesscode_enabled,
    accesscodeLink: event.accesscode_link,
    accesscodeLinkType: event.accesscode_link_type,
    accesscodeCopy: event.accesscode_copy,
    accessFailed: false,
    chatEnabled: event.chat_enabled === null ? true : event.chat_enabled,
    heartingEnabled:
      event.hearting_enabled === null ? true : event.hearting_enabled,
    statsVisible: event.stats_visible === null ? true : event.stats_visible,
    broadcasterId: event.broadcaster_id.toString(),
    legacyUrl: event.legacy_url,
    artworkMode: event.artwork_mode,
    lastUpdatedAt: event.updated_at,
    color: event.color,
    accessLevel: event.access_level,
    lastHubEventAt: null,
    lastHubEventType: null,
    artwork: event.media.artwork,
    fallback: event.fallback
  };
};

const mapApiEvent = (event: apiEvent): Event => {
  return mapApiV3Event({ data: event });
};

export const mapV3ChannelViewToPrivateEvents = (
  channel: V3ChannelView
): Event[] => {
  const upcomingPrivateEvents = channel.included.filter(
    (included): included is apiEvent => {
      return (
        included.type === 'event' && !!included.attributes.accesscode_enabled
      );
    }
  );

  return upcomingPrivateEvents.map(mapApiEvent);
};

export const mapV3ChannelViewToEvents = (channel: V3ChannelView): Event[] => {
  const upcomingEvents = channel.included.filter(
    (included): included is apiEvent => {
      return (
        included.type === 'event' && !included.attributes.accesscode_enabled
      );
    }
  );

  return upcomingEvents.map(mapApiEvent);
};

export const validateEventAccesscode =
  (subdomain: string, eventId: string, accesscode: string): AppThunk =>
  async (dispatch) => {
    const cookieString = `eac_${eventId}=${accesscode}; ${process.env.REACT_APP_COOKIE_SUFFIX}`;
    document.cookie = cookieString;

    try {
      const response = await apiV3GetEvent(subdomain, eventId);

      dispatch(addEvents([mapApiV3Event(response as V3Event)]));
    } catch (err) {
      if (err.status == 401) {
        dispatch(accessCodeFailure(eventId));
      }
    }
  };

export const setCurrentEvent =
  (eventId: string): AppThunk =>
  (dispatch) => {
    dispatch(setCurrentRecording(null));
    dispatch(setCurrentEventId(eventId));
  };

export const navigateErrorEvent =
  (
    redirect: NavigateFunction,
    isEmbed: boolean,
    isCreatorApp: boolean,
    postMessage?: any
  ): AppThunk =>
  async (dispatch) => {
    dispatch(unloadEvent());
    dispatch(createAlert({ message: 'Event removed', type: 'error' }));
    if (postMessage) {
      postMessage({
        eventRemoved: true
      });
    }
    if (isCreatorApp) {
      redirect(`/events/deleted`);
      return;
    }
    const navigationState = { state: { stopAudio: true } };
    if (isEmbed) {
      redirect(`/embed`, navigationState);
    } else {
      redirect(`/`, navigationState);
    }
  };
