import { sendChatMessage } from 'features/chat/chatSlice';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { useCurrentUser } from 'features/current-user/hooks';
import EmojiPicker from 'components/EmojiPicker';
import { EmojiClickData } from 'emoji-picker-react';
import GiphyPicker from 'components/GiphyPicker';
import { TenorImage } from 'gif-picker-react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { HeartButton } from 'components/Hearts/HeartButton';

interface ChatInputProps {
  channelId: string | null;
}

export const ChatInput: FunctionComponent<ChatInputProps> = (props) => {
  const textareaRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const socketId = useSelector((state: RootState) => state.hub.socketId);
  const [chatText, setChatText] = useState<string>('');
  const currentUser = useCurrentUser();
  const chatLabel = currentUser
    ? `Chat as ${currentUser.username}...`
    : 'Chat now...';

  const submitForm = () => {
    if (!props.channelId) {
      return;
    }
    const formattedText = chatText
      .replace(/<div>/gi, '<br>')
      .replace(/<\/div>/gi, '')
      .replace(/^<br>/, '');
    dispatch(sendChatMessage(props.channelId, formattedText, socketId));
    setChatText('');
    if (textareaRef.current) {
      textareaRef.current.blur();
      textareaRef.current.focus();
    }
  };

  const sendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitForm();
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitForm();
    }
  };

  const handleOnChange = (e: ContentEditableEvent) => {
    setChatText(e.target.value);
  };

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    setChatText(chatText + emojiObject.emoji);
  };

  const onGifClick = (gif: TenorImage) => {
    const text = filterTextImages(gif.url, gif.description);
    setChatText(chatText + text);
    textareaRef.current?.focus();
  };

  const filterTextImages = (url: string, description: string) => {
    const filterStringImages = url.replace(
      /(https?:\/\/\S+(\.gif))/g,
      `<img src="$&" alt="${description}"/>`
    );
    return filterStringImages;
  };

  return (
    <div className={styles['chat__input']}>
      <form
        onSubmit={sendMessage}
        className={styles['chat__form']}
        onKeyPress={onKeyPress}
      >
        <div className="tw-flex-1 tw-flex tw-space-x-2 tw-items-center">
          <HeartButton />
          <div className="tw-flex-1">
            <ContentEditable
              innerRef={textareaRef}
              html={chatText}
              disabled={false}
              onChange={handleOnChange}
              className={styles['chat__textarea']}
              placeholder={chatLabel}
            />
          </div>
        </div>
        <div className="tw-flex-none tw-flex tw-space-x-2 tw-items-center">
          <GiphyPicker onGifClick={onGifClick} />
          <EmojiPicker onEmojiClick={onEmojiClick} />
          <button className={styles['chat__submit']} type="submit">
            Post
          </button>
        </div>
      </form>
    </div>
  );
};
