export interface ApiChatMessage {
  id: number;
  content: string;
  user_id: number;
  display_name: string;
  created_at: string;
  profile_image_url: string;
  reactions: ApiChatMessageReaction[];
}

export interface ApiChatMessageReaction {
  reaction_type: string;
  author_ids: string[];
  count: number;
}

export async function apiGetChatHistory(
  channelId: string,
  limit: number,
  offset: number
): Promise<ApiChatMessage[]> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/users/${channelId}/comments?sort=asc&limit=${limit}&offset=${offset}`,
    { credentials: 'include' }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}

export async function apiSendChatMessage(
  channelId: string,
  content: string,
  socketId: string
): Promise<ApiChatMessage> {
  const formData = new FormData();

  formData.set('comment[content]', content);
  formData.set('socket_id', socketId);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/users/${channelId}/comments`,
    {
      method: 'post',
      credentials: 'include',

      body: formData
    }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export interface ApiBlockUserFromChatResponse {
  success: boolean;
}

export async function apiBlockUserFromChat(
  userId: string
): Promise<ApiBlockUserFromChatResponse> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/ignore`,
    {
      method: 'post',
      credentials: 'include'
    }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}

export interface ApiUnblockUserFromChatResponse {
  success: boolean;
}

export async function apiUnblockUserFromChat(
  userId: string
): Promise<ApiUnblockUserFromChatResponse> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/ignore`,
    {
      method: 'delete',
      credentials: 'include'
    }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}

export interface ApiDeleteChatMessageResponse {
  success: boolean;
}

export async function apiDeleteChatMessage(
  commentId: string,
  socketId: string
): Promise<ApiDeleteChatMessageResponse> {
  const formData = new FormData();
  formData.set('socket_id', socketId);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/comments/${commentId}`,
    {
      method: 'delete',
      credentials: 'include',
      body: formData
    }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}
