import React, {
  FunctionComponent,
  useState,
  useEffect,
  MouseEvent
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { formatTime } from '../../utilities/formatters';
import { useAudio } from 'features/audio/audio';
import { useAudioPosition } from 'features/audio/useAudioPosition';

export const SeekBar: FunctionComponent = () => {
  const [seekPosition, setSeekPosition] = useState<number>(0);
  const [barWidth, setBarWidth] = useState<string>('0%');
  const [bubbleValue, setBubbleValue] = useState<string>('00:00:00');
  const [bubblePosition, setBubblePosition] = useState<number>(0);
  const [showBubble, setShowBubble] = useState<boolean>(false);
  const { audio, play } = useAudio();
  const loading = useSelector((state: RootState) => state.audio.loading);
  const playing = useSelector((state: RootState) => state.audio.playing);
  const { duration, seek, percentComplete, position, seeking, setSeeking } =
    useAudioPosition();

  useEffect(() => {
    setBarWidth(`${percentComplete}%`);
  }, [percentComplete]);

  const onInput = (event: any) => {
    const newValue = Number(event.target.value);
    const progressPosition = ((newValue / duration) * 100) | 0;
    setSeeking(true);
    setShowBubble(true);
    setBarWidth(`${progressPosition}%`);
    setBubblePosition(progressPosition);
    setBubbleValue(formatTime(newValue));
    setSeekPosition(newValue);

    if (!playing) {
      play();
    }
  };

  const onMouseMove = (event: MouseEvent) => {
    if (event.target instanceof Element) {
      const maxValue = event.target.getAttribute('max') || '0';
      const newValue =
        (event.nativeEvent.offsetX / event.target.clientWidth) *
        parseInt(maxValue, 10);
      const progressPosition = ((newValue / duration) * 100) | 0;
      setShowBubble(true);
      setBubblePosition(progressPosition);
      setBubbleValue(formatTime(newValue));
    }
  };

  const onMouseOut = () => {
    setShowBubble(false);
  };

  const onSeekEnd = () => {
    seek(seekPosition);
    setSeeking(false);
  };

  const onMoveSeek = (event: any) => {
    const newValue = Number(event.target.value);
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      seek(newValue);
    }
  };

  const value = seeking ? seekPosition : audio?.currentTime || 0;
  return (
    <div className="tw-relative tw-mt-4 tw-w-full">
      <input
        id="seekBar"
        name="rangeInput"
        className={styles['seekbar__range']}
        type="range"
        disabled={loading || !audio}
        value={value}
        min={0}
        max={duration}
        onInput={onInput}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onMouseUp={onSeekEnd}
        onTouchEnd={onSeekEnd}
        onKeyDown={onMoveSeek}
        tabIndex={0}
        aria-label="Audio timeline"
        aria-valuemin={0}
        aria-valuemax={duration}
        aria-valuetext={value.toString()}
        aria-valuenow={value}
        style={{
          background: `
          linear-gradient(
          to right,
          var(--color__background) 0%,
          var(--color__background) ${barWidth},
          rgb(var(--color__white-rgb), .1) ${barWidth},
          rgb(var(--color__white-rgb), .1) 100%
        )
      `
        }}
      />
      {showBubble && (
        <div
          className="tw-absolute tw-bg-background tw-text-label tw-px-1 tw-rounded tw-bottom-full tw-text-base tw-mb-1 tw-tabular-nums -tw-translate-x-1/2 tw-shadow"
          style={{
            left: `calc(${bubblePosition}% + (${8 - bubblePosition * 0.15}px))`
          }}
        >
          {bubbleValue}
        </div>
      )}
      <div className={styles['seekbar__time']}>
        <span className="tnum">{formatTime(position)}</span>
        <span className="tnum">{formatTime(duration)}</span>
      </div>
      <label className="visually-hidden" htmlFor="seekBar">
        Seek through audio
      </label>
    </div>
  );
};
