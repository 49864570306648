import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiBlockUserFromChat,
  ApiChatMessage,
  ApiChatMessageReaction,
  apiDeleteChatMessage,
  ApiDeleteChatMessageResponse,
  apiGetChatHistory,
  apiSendChatMessage,
  apiUnblockUserFromChat
} from 'api/chat';
import {
  apiV3SendCommentReactionCreated,
  apiV3SendCommentReactionRemoved
} from 'api/v3/chat';
import { createAlert } from 'features/alert/alertSlice';
import {
  blockUserFromChatSuccess,
  unblockUserFromChatSuccess,
  resetCurrentUserState,
  showAuthenticationEmailPromptForm
} from 'features/current-user/currentUserSlice';
import {
  HubCommentCreatedMessage,
  HubActionHeartCreated
} from 'features/hub/hub';
import { AppThunk } from 'store';
import { uniqBy } from 'lodash';

export interface MessageState {
  id: string;
  content: string;
  user_id: string;
  display_name: string;
  created_at: string;
  profile_image_url: string;
  type: string;
  heartScale: number;
  reactions: ReactionState[];
}

export interface ReactionState {
  commentId: string;
  authorIds: string[];
  reactionType: string;
  count: number;
}

export interface Reaction {
  commentId: string;
  authorId: string;
  reactionType: string;
  socketId: string;
}

export interface SelectedUserState {
  id: string;
  username: string;
  profileImageUrl?: string;
}

export interface ChatState {
  messages: MessageState[];
  isLoaded: boolean;
  selectedUser: SelectedUserState | null;
  sendMessageLoading: boolean;
  isVisible: boolean;
  isNativeChatVisible: boolean;
  isHearting: boolean;
  hasNewComments: boolean;
  newCommentCount: number;
  currentUserSubmittedComment: boolean;
  loadingOnScroll: boolean;
  loadedAll: boolean;
}

export const initialChatState: ChatState = {
  messages: [],
  isLoaded: false,
  selectedUser: null,
  sendMessageLoading: false,
  isVisible: window.outerWidth < 740 || window.outerHeight < 410 ? false : true,
  isNativeChatVisible: false,
  isHearting: false,
  hasNewComments: false,
  newCommentCount: 0,
  currentUserSubmittedComment: false,
  loadingOnScroll: false,
  loadedAll: false
};

const mapApiHistoryToState = (history: ApiChatMessage[]): MessageState[] => {
  return history.map(mapApiMessageToState);
};

const mapApiMessageToState = (message: ApiChatMessage): MessageState => {
  return {
    id: message.id.toString(),
    content: message.content,
    user_id: message.user_id.toString(),
    display_name: message.display_name,
    created_at: message.created_at.toString(),
    profile_image_url: message.profile_image_url,
    type: 'comment',
    heartScale: 1,
    reactions:
      message.reactions?.map((r) =>
        mapApiMessageReactionToState(message.id.toString(), r)
      ) || []
  };
};

const mapApiMessageReactionToState = (
  comment_id: string,
  reaction: ApiChatMessageReaction
): ReactionState => {
  return {
    commentId: comment_id,
    reactionType: reaction.reaction_type,
    authorIds: reaction.author_ids,
    count: reaction.count
  };
};

const mapHubMessageToState = (
  comment: HubCommentCreatedMessage
): MessageState => {
  return {
    id: comment.id.toString(),
    content: comment.content,
    user_id: comment.user_id,
    display_name: comment.name,
    created_at: comment.created_at.toString(),
    profile_image_url: comment.image_url,
    type: 'comment',
    heartScale: 1,
    reactions: []
  };
};

const mapHubHeartMessageToState = (
  heart: HubActionHeartCreated
): MessageState => {
  return {
    id: heart.created_at + '-' + heart.user_id.toString(),
    content: heart.display_name,
    user_id: heart.user_id.toString(),
    display_name: heart.display_name,
    created_at: heart.created_at,
    profile_image_url: '',
    type: 'heart',
    heartScale: 0.4,
    reactions: []
  };
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialChatState,
  reducers: {
    getChatHistorySuccess(state, action: PayloadAction<ApiChatMessage[]>) {
      // If loading on scroll, we need to add to the
      // end of the messages, rather than the start
      const messages = state.loadingOnScroll
        ? [...mapApiHistoryToState(action.payload), ...state.messages]
        : [...state.messages, ...mapApiHistoryToState(action.payload)];
      const messagesUniq = uniqBy(messages, 'id');
      const lastComment = messagesUniq[messagesUniq.length - 1];
      state.isHearting = lastComment && lastComment.type === 'heart';
      state.messages = messagesUniq;
      state.isLoaded = true;
    },
    getChatHistoryFailed(_state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    addNewMessage(state, action: PayloadAction<HubCommentCreatedMessage>) {
      const message = mapHubMessageToState(action.payload);
      state.messages.push(message);
      state.isHearting = false;
      state.hasNewComments = true;
      state.newCommentCount++;
    },
    addHeartMessage(state, action: PayloadAction<HubActionHeartCreated>) {
      const message = mapHubHeartMessageToState(action.payload);
      if (state.isHearting) {
        state.messages = state.messages.map((comment, index, { length }) => {
          if (index === length - 1 && comment.type === 'heart') {
            const addUser = comment.content
              .concat(', ')
              .concat(message.display_name);
            comment.content = addUser;
            if (comment.heartScale < 1) {
              comment.heartScale = comment.heartScale + 0.05;
            }
          }
          return comment;
        });
      } else {
        state.messages.push(message);
        state.isHearting = true;
      }
    },
    sendChatMessageLoading(state, action: PayloadAction<boolean>) {
      state.sendMessageLoading = action.payload;
    },
    sendChatMessageSuccess(state, action: PayloadAction<ApiChatMessage>) {
      const stateMessage = mapApiMessageToState(action.payload);
      state.messages.push(stateMessage);
      state.isHearting = false;
      state.hasNewComments = true;
      state.newCommentCount++;
    },
    setSelectedChatUserId(
      state,
      action: PayloadAction<SelectedUserState | null>
    ) {
      state.selectedUser = action.payload;
    },
    deleteMessageSuccess(state, action: PayloadAction<string>) {
      state.messages = state.messages.filter((message) => {
        return message.id !== action.payload;
      });
    },
    setChatVisible(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload;
    },
    setNativeChatVisible(state, action: PayloadAction<boolean>) {
      state.isNativeChatVisible = action.payload;
    },
    setHasNewComments(state, action: PayloadAction<boolean>) {
      state.hasNewComments = action.payload;
      state.newCommentCount = action.payload ? state.newCommentCount : 0;
    },
    setNewCommentCount(state, action: PayloadAction<number>) {
      state.newCommentCount = action.payload;
    },
    setCurrentUserSubmittedComment(state, action: PayloadAction<boolean>) {
      state.currentUserSubmittedComment = action.payload;
    },
    setLoadingOnScroll(state, action: PayloadAction<boolean>) {
      state.loadingOnScroll = action.payload;
    },
    setLoadedAll(state, action: PayloadAction<boolean>) {
      state.loadedAll = action.payload;
    },
    setIsLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    addReaction(state, action: PayloadAction<Reaction>) {
      state.messages = state.messages.map((comment) => {
        if (comment.id === action.payload.commentId) {
          const existingReactionState = comment.reactions.find(
            (r) => r.reactionType === action.payload.reactionType
          );

          if (!existingReactionState) {
            // Initialize new reaction according to ReactionState interface
            comment.reactions.push({
              commentId: action.payload.commentId,
              authorIds: [action.payload.authorId],
              reactionType: action.payload.reactionType,
              count: 1
            });
          } else if (
            !existingReactionState.authorIds.includes(action.payload.authorId)
          ) {
            existingReactionState.authorIds.push(action.payload.authorId);
            existingReactionState.count += 1;
          }
        }
        return comment;
      });
    },
    removeReaction(state, action: PayloadAction<Reaction>) {
      // state.messages = state.messages.map((comment) => {
      //   if (comment.id === action.payload.commentId) {
      //     const reactionIndex = comment.reactions.findIndex(
      //       (c) => c.reactionType === action.payload.reactionType
      //     );
      //
      //     if (reactionIndex !== -1) {
      //       const count = comment.reactions[reactionIndex].count;
      //       if (count === 1) {
      //         comment.reactions.splice(reactionIndex, 1);
      //       } else {
      //         comment.reactions[reactionIndex].count -= 1;
      //       }
      //     }
      //   }
      //   return comment;
      // });
      state.messages = state.messages.map((comment) => {
        if (comment.id === action.payload.commentId) {
          const existingReactionState = comment.reactions.find(
            (r) => r.reactionType === action.payload.reactionType
          );

          if (
            existingReactionState &&
            existingReactionState.authorIds.includes(action.payload.authorId)
          ) {
            existingReactionState.authorIds =
              existingReactionState.authorIds.filter(
                (id) => id !== action.payload.authorId
              );
            existingReactionState.count -= 1;

            // Remove the reaction entirely if no authors left
            if (existingReactionState.count === 0) {
              comment.reactions = comment.reactions.filter(
                (r) => r.reactionType !== action.payload.reactionType
              );
            }
          }
        }
        return comment;
      });
    }
  }
});

export const {
  getChatHistorySuccess,
  getChatHistoryFailed,
  addNewMessage,
  sendChatMessageSuccess,
  setSelectedChatUserId,
  deleteMessageSuccess,
  sendChatMessageLoading,
  setChatVisible,
  setNativeChatVisible,
  addHeartMessage,
  setHasNewComments,
  setCurrentUserSubmittedComment,
  setLoadingOnScroll,
  setLoadedAll,
  setIsLoaded,
  addReaction,
  removeReaction
} = chatSlice.actions;

export default chatSlice.reducer;

export const fetchChatHistory =
  (channelId: string, fetchHistory?: boolean, offset?: number): AppThunk =>
  async (dispatch) => {
    try {
      const fetchLimit = fetchHistory ? 250 : 20;
      const fetchOffset = offset || 0;
      const chatHistory = await apiGetChatHistory(
        channelId,
        fetchLimit,
        fetchOffset
      );
      if (chatHistory.length > 0) {
        dispatch(getChatHistorySuccess(chatHistory));
      } else {
        dispatch(setLoadedAll(true));
        dispatch(setIsLoaded(true));
      }
      dispatch(setLoadingOnScroll(false));
    } catch (err) {
      dispatch(getChatHistoryFailed(err));
    }
  };

export const handleReaction =
  (reaction: Reaction): AppThunk =>
  async (dispatch, getState) => {
    const messages = getState().chat.messages;
    const existingComment = messages.find((m) => m.id === reaction.commentId);

    if (!existingComment) return;

    const existingReactionState = existingComment.reactions.find(
      (r) => r.reactionType === reaction.reactionType
    );

    if (
      existingReactionState &&
      existingReactionState.authorIds?.includes(reaction.authorId)
    ) {
      dispatch(removeReaction(reaction));
      await apiV3SendCommentReactionRemoved(
        reaction.commentId,
        reaction.reactionType,
        reaction.socketId
      );
    } else {
      dispatch(addReaction(reaction));
      await apiV3SendCommentReactionCreated(
        reaction.commentId,
        reaction.reactionType,
        reaction.socketId
      );
    }
  };

export const sendChatMessage =
  (channelId: string, content: string, socketId: string): AppThunk =>
  async (dispatch) => {
    try {
      const message = await apiSendChatMessage(channelId, content, socketId);
      dispatch(sendChatMessageLoading(false));
      dispatch(sendChatMessageSuccess(message));
      dispatch(setCurrentUserSubmittedComment(true));
    } catch (err) {
      if (err.status === 400) {
        dispatch(
          createAlert({
            message: 'You are blocked from chatting!',
            type: 'notify'
          })
        );
        dispatch(setCurrentUserSubmittedComment(false));
        dispatch(sendChatMessageLoading(false));
      }

      if (err.status === 401) {
        dispatch(resetCurrentUserState());
        dispatch(showAuthenticationEmailPromptForm());
      }
    }
  };

export const blockUserFromChat =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const result = await apiBlockUserFromChat(userId);
      if (result.success === true) {
        dispatch(blockUserFromChatSuccess(userId));
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };

export const unblockUserFromChat =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const result = await apiUnblockUserFromChat(userId);
      if (result.success === true) {
        dispatch(unblockUserFromChatSuccess(userId));
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };

export const deleteChatMessage =
  (commentId: string, socketId: string): AppThunk =>
  async (dispatch) => {
    try {
      const result: ApiDeleteChatMessageResponse = await apiDeleteChatMessage(
        commentId,
        socketId
      );
      if (result.success === true) {
        dispatch(deleteMessageSuccess(commentId));
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };
