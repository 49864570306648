import { UserAgentContext } from 'features/user-agent/userAgent';
import React, { FunctionComponent, ReactNode, useContext } from 'react';

interface FullScreenContentProps {
  player?: ReactNode;
  centerColumn: ReactNode;
  rightColumn?: ReactNode | null;
  renderAtBottom?: boolean;
}

export const FullScreenContent: FunctionComponent<FullScreenContentProps> = (
  props
) => {
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const layoutListener = isListenerAppWebview
    ? ''
    : 'mobile-landscape:tw-flex-row';
  return (
    <div
      className={`tw-flex tw-flex-col sm:tw-flex-row ${layoutListener} tw-flex-1 sm:tw-px-8 sm:tw-pr-8 sm:tw-pb-8 sm:tw-space-x-8 tw-relative tw-z-50 [.embed_&]:tw-pb-0 [.browser-embed_&]:tw-pt-[52px]`}
    >
      <div className="tw-flex tw-flex-1 tw-items-center tw-justify-center tw-text-center">
        <div className="tw-flex tw-flex-col tw-h-full tw-w-full">
          <div
            className={`tw-flex tw-flex-1 tw-justify-center ${
              props.renderAtBottom ? 'tw-items-end' : 'tw-items-center'
            } tw-w-full`}
          >
            {props.centerColumn}
          </div>
          {props.player && props.player}
        </div>
      </div>
      {props.rightColumn && props.rightColumn}
    </div>
  );
};
