import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
  useCallback
} from 'react';
import { useDispatch } from 'react-redux';
import { createAlert } from 'features/alert/alertSlice';
import {
  setConnecting,
  setReady,
  setLoading,
  setPlaying,
  setPlay,
  setDuration,
  setError,
  resetAudioState
} from 'features/audio/audioSlice';
import { AudioContext } from 'standardized-audio-context';
import { DateTime } from 'luxon';
import { setAudioEndedTimestamp } from 'features/broadcast/broadcastsSlice';
import { checkAndPlayAudio } from 'features/audio/audioThunks';
import {
  currentBroadcastFinishedSelector,
  currentEventBroadcastSelector
} from 'features/broadcast/selectors';
import { useLocalStorage } from 'usehooks-ts';
import { isSafari } from 'react-device-detect';
import { useAppSelector } from 'hooks/redux';

export interface PlayerConfigInterface {
  audioSrc: string;
  autoPlay: boolean;
  audioType: 'event' | 'recording';
}

export type LoadAudioFunction = (config: PlayerConfigInterface) => any;

export interface AudioPlayerContext {
  audio: HTMLAudioElement | null;
  audioCtx: AudioContext | null;
  volume: number;
  setVolume: (volume: number | ((prevState: number) => number)) => void;
  loadAudio: (config: PlayerConfigInterface) => void;
  unlockAudio: () => void;
  play: () => void;
  stop: (resetAudio?: boolean) => void;
  pause: () => void;
  togglePlayPause: () => void;
  disconnect: () => void;
}

export interface AudioPlayerPositionContext {
  seeking: boolean;
  setSeeking: (seeking: boolean) => void;
  position: number;
  setPosition: (position: number) => void;
}

export const initialState: AudioPlayerContext = {
  audio: null,
  audioCtx: null,
  volume: 1,
  setVolume: () => null,
  play: () => null,
  loadAudio: () => null,
  unlockAudio: () => null,
  stop: () => null,
  pause: () => null,
  togglePlayPause: () => null,
  disconnect: () => null
};

export const AudioPlayerContext =
  createContext<AudioPlayerContext>(initialState);

export const AudioPositionContext = createContext<AudioPlayerPositionContext>({
  seeking: false,
  setSeeking: () => null,
  position: 0,
  setPosition: () => null
});

export const useAudio = () => {
  const context = useContext(AudioPlayerContext);

  if (!context && typeof window !== 'undefined') {
    throw new Error(`useAudio must be used within a AudioContext`);
  }
  return context;
};

export interface AudioPlayerProviderProps {
  children: React.ReactNode;
  value?: AudioPlayerContext;
}

export const AudioPlayerProvider = ({
  children,
  value
}: AudioPlayerProviderProps) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [audioCtx, setAudioCtx] = useState<AudioContext | null>(null);
  const [src, setSrc] = useState<string | null>(null);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const dispatch = useDispatch();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const reconnectRef = useRef<NodeJS.Timeout>();
  const errorRetryRef = useRef<number>(0);
  const audioSetupRef = useRef<boolean>(false);
  const audioUnlockRef = useRef<boolean>(false);
  const audioCtxSetupRef = useRef<boolean>(false);
  const currentBroadcastFinishedRef = useRef<boolean>(false);
  const [position, setPosition] = useState<number>(0);
  const [seeking, setSeeking] = useState<boolean>(false);
  const positionContextValue = useMemo(
    () => ({
      seeking,
      setSeeking,
      position,
      setPosition
    }),
    [seeking, setSeeking, position, setPosition]
  );
  const playing = useAppSelector((state) => state.audio.playing);
  const currentEventId = useAppSelector((state) => state.events.currentEventId);

  const [volume, setVolume] = useLocalStorage('volume', 1);

  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );

  const silentAudio =
    'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=';

  const unlockAudio = useCallback(() => {
    // Safari won't autoplay with async events even
    // if the user has interacted with the dom :(
    if (!isSafari) return;
    if (!audioUnlockRef.current) {
      console.log('[Audio] - Unlocking audio');
      const sound = new Audio(silentAudio);
      sound.volume = 0;
      sound.play().then(() => {
        sound.pause();
        sound.currentTime = 0;
      });
      audioUnlockRef.current = true;
    }
  }, []);

  const loadAudio = useCallback(
    (config: PlayerConfigInterface) => {
      console.log('[Audio] - Setting config');
      const url =
        process.env.REACT_APP_ENV_NAME === 'development'
          ? config.audioType === 'event'
            ? 'https://radiomeuh2.ice.infomaniak.ch/radiomeuh2-128.mp3'
            : 'https://ilo-voices.cdn.prismic.io/ilo-voices/c336ed2f-9e7f-4303-a426-590a63fd8462_The+Future+of+Work+Podcast+-+Green+Jobs+%28French%29.mp3'
          : config.audioSrc;
      unlockAudio();
      dispatch(checkAndPlayAudio(url, config.audioType, setSrc));
      setAutoPlay(config.autoPlay);
    },
    [dispatch, setAutoPlay, unlockAudio, setSrc]
  );

  const reset = useCallback(
    (resetAudio?: boolean) => {
      setSrc(null);
      setAudio(null);
      setAudioCtx(null);
      setAutoPlay(false);
      audioSetupRef.current = false;

      if (resetAudio) {
        dispatch(resetAudioState());
      } else {
        dispatch(setPlay(false));
        dispatch(setPlaying(false));
      }
    },
    [setSrc, setAudio, setAudioCtx, setAutoPlay, dispatch]
  );

  const play = useCallback(() => {
    if (!audio) return;
    dispatch(setLoading(true));
    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          console.log('[Audio] - Play Promise success');
        })
        .catch((error) => {
          console.log('[Audio] - Play Promise error', error);
          // Autoplay could fail because of no user interaction (click)
          // Let the user manually play the audio now
          dispatch(setLoading(false));
          dispatch(setPlaying(false));
          dispatch(setPlay(false));
          audio.pause();
          audio.currentTime = 0;
        });
    }
  }, [audio, dispatch]);

  const pause = useCallback(() => {
    if (audio) {
      audio.pause();
      dispatch(setPlaying(false));
    }
  }, [audio, dispatch]);

  const togglePlayPause = useCallback(() => {
    if (!audio) return;
    if (playing) {
      dispatch(setPlaying(false));
      dispatch(setPlay(false));
      audio.pause();
    } else {
      dispatch(setPlaying(true));
      play();
    }
  }, [audio, dispatch, playing, play]);

  const handleReconnect = useCallback(() => {
    if (!src) return;
    if (errorRetryRef.current < 5) {
      errorRetryRef.current++;
      console.log('[Audio] - Reconnecting', errorRetryRef.current);
      reconnectRef.current = setTimeout(() => {
        setSrc(null);
        setSrc(src);
      }, 2000);
    } else {
      reset();
      if (audio && audio.error) {
        dispatch(
          createAlert({
            message: 'Error loading audio',
            type: 'error'
          })
        );
        switch (audio.error.code) {
          case audio.error.MEDIA_ERR_ABORTED:
            console.log('[Audio] - Error - Aborted the video playback.');
            dispatch(setError(new Error(`${audio.error.MEDIA_ERR_ABORTED}`)));
            break;
          case audio.error.MEDIA_ERR_NETWORK:
            console.log(
              '[Audio] - Error - A network error caused the audio download to fail.'
            );
            dispatch(setError(new Error(`${audio.error.MEDIA_ERR_NETWORK}`)));
            break;
          case audio.error.MEDIA_ERR_DECODE:
            console.log(
              '[Audio] - Error - The audio playback was aborted due to a corruption problem or because the video used features your browser did not support.'
            );
            dispatch(setError(new Error(`${audio.error.MEDIA_ERR_DECODE}`)));
            break;
          case audio.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
            console.log(
              '[Audio] - Error - The video audio not be loaded, either because the server or network failed or because the format is not supported.'
            );
            dispatch(
              setError(new Error(`${audio.error.MEDIA_ERR_SRC_NOT_SUPPORTED}`))
            );
            break;
          default:
            console.log('[Audio] - Error - An unknown error occurred.');
            break;
        }
      }
    }
  }, [audio, dispatch, reset, src]);

  const handleStalled = useCallback(() => {
    console.log('[Audio] - Stalling');
  }, []);

  const handleWaiting = useCallback(() => {
    console.log('[Audio] - Waiting');
  }, []);

  const handleCanPlay = useCallback(() => {
    console.log('[Audio] - Can play through');
    dispatch(setConnecting(false));
    dispatch(setLoading(false));
    dispatch(setReady(true));
  }, [dispatch]);

  const handleEnded = useCallback(() => {
    console.log('[Audio] - Ended');
    dispatch(setAudioEndedTimestamp(DateTime.now().toSeconds()));

    if (!audio) return;
    if (currentEventId) {
      if (currentBroadcastFinishedRef.current) {
        reset(true);
      } else {
        console.log(
          '[Audio] - Ended but broadcast not finished, trying to reconnect'
        );
        if (src) {
          reset();
          dispatch(setConnecting(true));
          setTimeout(() => {
            loadAudio({
              audioSrc: src,
              autoPlay: true,
              audioType: 'event'
            });
          }, 5000);
        }
      }
    } else {
      audio.currentTime = 0;
      setSeeking(false);
      setPosition(0);
    }
  }, [src, loadAudio, audio, dispatch, currentEventId, reset]);

  const handleTimeUpdate = useCallback(() => {
    // If timeupdates stop for a certain amount of time,
    // we can reliably assume that the audio is buffering
    // Check we are not just pausing though, as this is also
    // fired when pausing audio
    timeoutRef.current && clearTimeout(timeoutRef.current);
    dispatch(setConnecting(false));
    timeoutRef.current = setTimeout(() => {
      if (audio && !audio.paused) {
        dispatch(setConnecting(true));
      }
    }, 3000);
  }, [audio, dispatch]);

  const handlePlay = useCallback(() => {
    console.log('[Audio] - Play');
    dispatch(setPlay(true));
  }, [dispatch]);

  const handlePlaying = useCallback(() => {
    console.log('[Audio] - Playing');
    dispatch(setConnecting(false));
    dispatch(setLoading(false));
    dispatch(setPlaying(true));
    errorRetryRef.current = 0;
    reconnectRef.current && clearTimeout(reconnectRef.current);
  }, [dispatch]);

  const handlePause = useCallback(() => {
    console.log('[Audio] - Paused');
    dispatch(setPlaying(false));
    dispatch(setPlay(false));
  }, [dispatch]);

  const handleError = useCallback(
    (event: ErrorEvent) => {
      console.log('[Audio] - Error', event);
      if (audio && audio.error) {
        handleReconnect();
      }
    },
    [audio, handleReconnect]
  );

  // Mainly used for testing
  const disconnect = useCallback(() => {
    console.log('[Audio] - Disconnecting audio stream');
    handleReconnect();
  }, [handleReconnect]);

  const handleLoadedData = useCallback(() => {
    console.log('[Audio] - Loaded Data');
    if (audio) {
      dispatch(setDuration(audio.duration));
    }
  }, [dispatch, audio]);

  const stop = useCallback(
    (resetAudio: boolean | undefined) => {
      console.log('[Audio] - Stop and reset');
      dispatch(setAudioEndedTimestamp(DateTime.now().toSeconds()));

      if (audio) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        audio.removeEventListener('loadeddata', handleLoadedData);
        audio.removeEventListener('stalled', handleStalled);
        audio.removeEventListener('waiting', handleWaiting);
        audio.removeEventListener('canplaythrough', handleCanPlay);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('play', handlePlay);
        audio.removeEventListener('playing', handlePlaying);
        audio.removeEventListener('pause', handlePause);
        audio.removeEventListener('error', handleError);
        audio.pause();
        audio.src = silentAudio;
        audio.currentTime = 0;
        audio.remove();
      }

      reset(resetAudio);
    },
    [
      dispatch,
      audio,
      reset,
      handleLoadedData,
      handleStalled,
      handleWaiting,
      handleCanPlay,
      handleTimeUpdate,
      handleEnded,
      handlePlay,
      handlePlaying,
      handlePause,
      handleError
    ]
  );

  useEffect(() => {
    currentBroadcastFinishedRef.current = currentBroadcastFinished;
  }, [currentBroadcastFinished]);

  useEffect(() => {
    if (currentBroadcast?.fallback && currentBroadcastFinished) {
      dispatch(setAudioEndedTimestamp(DateTime.now().toSeconds()));
      stop(true);
    }
  }, [currentBroadcast, currentBroadcastFinished, dispatch, stop]);

  useLayoutEffect(() => {
    if (!src) return;
    if (!audioSetupRef.current) {
      console.log('[Audio] - Setting audio');
      const newAudio = new Audio();
      newAudio.crossOrigin = 'anonymous';
      newAudio.src = src;
      newAudio.volume = volume;
      newAudio.load();
      setAudio(newAudio);
      setDuration(0);
      audioSetupRef.current = true;
    }
  }, [src, setAudio, volume]);

  useEffect(() => {
    if (!audio) return;
    if (!audioCtxSetupRef.current) {
      const newContext = new AudioContext();
      setAudioCtx(newContext);
      audioCtxSetupRef.current = true;
    }

    return () => {
      if (audioCtx && audioCtx.state !== 'closed') {
        try {
          audioCtx.close();
          audioCtxSetupRef.current = false;
          setAudioCtx(null);
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, [audio, audioCtx]);

  useEffect(() => {
    if (audio && autoPlay) {
      dispatch(setLoading(true));
      play();
    }
  }, [autoPlay, audio, dispatch, play]);

  useEffect(() => {
    if (audio) {
      audio.volume = volume;
    }
  }, [volume, audio]);

  useEffect(() => {
    if (audio) {
      audio.addEventListener('loadeddata', handleLoadedData);
      audio.addEventListener('stalled', handleStalled);
      audio.addEventListener('waiting', handleWaiting);
      audio.addEventListener('canplaythrough', handleCanPlay);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('play', handlePlay);
      audio.addEventListener('playing', handlePlaying);
      audio.addEventListener('pause', handlePause);
      audio.addEventListener('error', handleError);
    }
  }, [
    audio,
    handleLoadedData,
    handleStalled,
    handleWaiting,
    handleCanPlay,
    handleTimeUpdate,
    handleEnded,
    handlePlay,
    handlePlaying,
    handlePause,
    handleError
  ]);

  useEffect(
    function setAudioMediaSession() {
      if (!audio) return;
    },
    [audio]
  );

  const contextValue: AudioPlayerContext = useMemo(() => {
    return value
      ? value
      : {
          loadAudio,
          unlockAudio,
          play,
          pause,
          stop,
          audio,
          audioCtx,
          volume,
          setVolume,
          togglePlayPause,
          value,
          disconnect
        };
  }, [
    loadAudio,
    unlockAudio,
    play,
    pause,
    stop,
    audio,
    audioCtx,
    volume,
    setVolume,
    togglePlayPause,
    value,
    disconnect
  ]);

  return (
    <AudioPlayerContext.Provider value={contextValue}>
      <AudioPositionContext.Provider value={positionContextValue}>
        {children}
      </AudioPositionContext.Provider>
    </AudioPlayerContext.Provider>
  );
};
