import React, { FunctionComponent, useContext, useEffect } from 'react';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.scss';
import { AuthModal } from 'features/auth/AuthModal';
import ReactModal from 'react-modal';
import { AutoplayPrompt } from 'features/events/AutoplayPrompt';
import { Alert } from 'features/alert/alert';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Privacy } from 'features/privacy/privacy';
import { ChannelSubscription } from 'features/channel_subscription/channelSubscription';
import { EventMiniPlayer } from 'features/events/components/EventMiniPlayer';
import { RecordingMiniPlayer } from 'features/recordings/components/RecordingMiniPlayer';
import { Search } from 'features/search/Search';
import { StateTester } from 'features/statetester/StateTester';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useAudio } from 'features/audio/audio';
import { useDispatch } from 'react-redux';
import { WebSocketContext } from 'features/hub/hub';
import { useOnceEffect } from 'hooks/useOnceEffect';
import { setCurrentVolume } from 'features/audio/audioSlice';
import { AnimatedRouteSwitch } from 'AnimatedRouteSwitch';
import { SendRecordingListenEvents } from 'features/recordings/components/SendRecordingListenEvents';
import { HelmetTags } from 'components/Helmet';
import './tailwind.css';
import { LiveBanner } from 'features/events/components/LiveBanner';
import { useBypassAccessCodeNetwork } from 'features/channel/channelHooks';
import { ChannelNetwork } from 'features/channel/components/ChannelNetwork';

const App: FunctionComponent = () => {
  const { isNativeAppWebview, isDACreatorApp, isListenerAppWebview } =
    useContext(UserAgentContext);
  const {
    postMessage,
    dynamicSizeUpdatesEvent,
    qWebChannel,
    eventWindowEvents
  } = useNativeEvents();
  const { connectToSocket, leaveCrowd } = useContext(WebSocketContext);
  const { setVolume, volume, stop } = useAudio();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation() as any;
  const bypassAccessCodeNetwork = useBypassAccessCodeNetwork();

  const { requiresAccesscode, requiresChannelSubscription } = useSelector(
    (state: RootState) => {
      return {
        requiresAccesscode: state.channel.requiresAccesscode,
        requiresChannelSubscription: state.channel.requiresChannelSubscription
      };
    }
  );

  useEffect(
    function stopAudioFromEventErrorRedirect() {
      if (location.state && location.state.stopAudio) {
        stop(true);
      }
    },
    [location, stop]
  );

  useEffect(() => {
    if (isNativeAppWebview) {
      postMessage('javascriptLoaded');
      dynamicSizeUpdatesEvent();
    }
  }, [postMessage, dynamicSizeUpdatesEvent, isNativeAppWebview]);

  useEffect(() => {
    connectToSocket();
  }, [connectToSocket]);

  useEffect(() => {
    return () => {
      leaveCrowd();
    };
  }, [leaveCrowd]);

  useEffect(() => {
    if (isDACreatorApp) {
      qWebChannel();
    }
  }, [isDACreatorApp, qWebChannel]);

  useEffect(() => {
    if (isNativeAppWebview) {
      eventWindowEvents(pathname, navigate);
    }
  }, [isNativeAppWebview, eventWindowEvents, pathname, navigate]);

  useEffect(() => {
    if (isListenerAppWebview) {
      postMessage({
        requiresAccesscode: requiresAccesscode
      });
    }
  }, [requiresAccesscode, isListenerAppWebview, postMessage]);

  useEffect(() => {
    if (isDACreatorApp) {
      qWebChannel();
    }
  }, [isDACreatorApp, qWebChannel]);

  useOnceEffect(() => {
    dispatch(setCurrentVolume(volume));
  });

  useEffect(() => {
    const handleUnload = () => {
      if (volume === 0) {
        setVolume(1);
      }
      leaveCrowd();
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [volume, setVolume, leaveCrowd]);

  if (bypassAccessCodeNetwork) {
    return (
      <>
        <HelmetTags />
        <ChannelNetwork />
      </>
    );
  }

  if (requiresAccesscode) {
    return (
      <>
        <HelmetTags />
        <Privacy />
      </>
    );
  }

  if (requiresChannelSubscription) {
    return (
      <>
        <HelmetTags />
        <ChannelSubscription />
        <AuthModal />
      </>
    );
  }

  return (
    <>
      <HelmetTags />
      <Alert />
      <AnimatedRouteSwitch />
      <AuthModal />
      <AutoplayPrompt />
      <LiveBanner />
      <EventMiniPlayer />
      <RecordingMiniPlayer />
      <SendRecordingListenEvents />
      <Search />
      {(process.env.REACT_APP_ENV_NAME === 'development' ||
        process.env.REACT_APP_ENV_NAME === 'staging') && <StateTester />}
    </>
  );
};

ReactModal.setAppElement('#root');

export default App;
