import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useContext,
  useCallback
} from 'react';
import { Player } from 'components/Player';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useAudio } from 'features/audio/audio';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useLocation } from 'react-router-dom';
import { fetchAudioAbort } from 'features/audio/audioThunks';
import { useNativefullScreenChat } from 'features/chat/chatHooks';

export const EventPlayer: FunctionComponent = () => {
  const { isNativeAppWebview, isBrowserEmbed } = useContext(UserAgentContext);
  const audioUrl = useSelector((state: RootState) => state.audio.url);
  const playing = useSelector((state: RootState) => state.audio.playing);
  const { audio, loadAudio, play, stop } = useAudio();
  const location = useLocation() as any;
  const hasLoadededAudio = useRef<boolean>(false);
  const nativefullScreenChat = useNativefullScreenChat();

  const loadEventAudio = useCallback(() => {
    if (!audioUrl) return;
    if (isNativeAppWebview || isBrowserEmbed) return;
    loadAudio({
      audioSrc: audioUrl,
      autoPlay: true,
      audioType: 'event'
    });
    hasLoadededAudio.current = true;
  }, [audioUrl, loadAudio, isNativeAppWebview, isBrowserEmbed]);

  useEffect(
    function autoPlayAudioAfterNavigation() {
      if (hasLoadededAudio.current) return;
      if (location.state && location.state.autoPlay) {
        loadEventAudio();
      }
    },
    [location, loadEventAudio]
  );

  useEffect(
    function loadAudioIfBroadcastUrlChanges() {
      if (hasLoadededAudio.current) {
        fetchAudioAbort();
        loadEventAudio();
      }
    },
    [audioUrl, loadEventAudio]
  );

  if (nativefullScreenChat) {
    return (
      <div className="tw-relative tw-w-full tw-text-center tw-h-[132px]"></div>
    );
  }

  if (isNativeAppWebview || isBrowserEmbed) {
    return null;
  }

  if (!audioUrl) {
    return null;
  }

  const onClick = () => {
    if (audio) {
      if (playing) {
        stop();
      } else {
        play();
      }
    } else {
      loadEventAudio();
    }
  };

  return (
    <div className="tw-relative tw-w-full tw-text-center tw-h-[100px]">
      <Player onClick={onClick} type={'event'} />
    </div>
  );
};
