import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Broadcast {
  uid: string;
  title: string;
  duration: number | null;
  categoryName: string;
  isLive: boolean;
  listenerCount: number | null;
  url: string;
  heartCount?: number;
  startedAt: string | null;
  eventId: string | null;
  finished: boolean;
  fallback: boolean;
}

export interface BroadcastsState {
  broadcasts: Broadcast[];
  lastAudioEndedTimestamp: number | null;
  heartLoading: boolean;
}

export const initialBroadcastsState: BroadcastsState = {
  broadcasts: [],
  lastAudioEndedTimestamp: null,
  heartLoading: false
};

const broadcastsSlice = createSlice({
  name: 'broadcasts',
  initialState: initialBroadcastsState,
  reducers: {
    addBroadcast(state, { payload: newBroadcast }: PayloadAction<Broadcast>) {
      const existingBroadcastIndex = state.broadcasts.findIndex(
        (broadcast) => broadcast.uid === newBroadcast.uid
      );

      if (existingBroadcastIndex >= 0) {
        const existingBroadcast = state.broadcasts[existingBroadcastIndex];
        if (!existingBroadcast.isLive && existingBroadcast.finished) {
          console.log(
            '[Broadcast Slice] - Existing broadcast is finished, not updating',
            JSON.stringify(existingBroadcast)
          );
          return;
        }
        state.broadcasts[existingBroadcastIndex] = {
          ...existingBroadcast,
          ...newBroadcast
        };
      } else {
        const filteredBroadcastsByEvent = state.broadcasts.filter(
          (broadcast) => broadcast.eventId !== newBroadcast.eventId
        );
        filteredBroadcastsByEvent.push(newBroadcast);
        state.broadcasts = filteredBroadcastsByEvent;
      }
    },
    updateBroadcast(
      state,
      { payload: updatedBroadcast }: PayloadAction<Partial<Broadcast>>
    ) {
      if (!updatedBroadcast.uid) {
        return;
      }

      const existingBroadcastIndex = state.broadcasts.findIndex(
        (broadcast) => broadcast.uid === updatedBroadcast.uid
      );

      const existingBroadcast = state.broadcasts[existingBroadcastIndex];

      state.broadcasts[existingBroadcastIndex] = {
        ...existingBroadcast,
        ...updatedBroadcast
      };
    },
    setAudioEndedTimestamp: (
      state,
      { payload: timestamp }: PayloadAction<number | null>
    ) => {
      state.lastAudioEndedTimestamp = timestamp;
    },
    setHeartLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
      state.heartLoading = loading;
    }
  }
});

export const {
  addBroadcast,
  setAudioEndedTimestamp,
  setHeartLoading,
  updateBroadcast
} = broadcastsSlice.actions;

export default broadcastsSlice.reducer;
