import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext
} from 'react';
import { useAppSelector } from 'hooks/redux';
import {
  fetchSearchResults,
  SearchRecording
} from 'features/search/searchSlice';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { Loader } from 'components/Loader';
import { Event } from 'features/events/eventsSlice';
import { SearchBar } from './Components/SearchBar';
import { SearchFilters } from './Components/SearchFilters';
import { SearchEmpty } from './Components/SearchEmpty';
import SearchResultEvent from './Components/SearchResult/Event';
import SearchResultRecording from './Components/SearchResult/Recording';
import { SearchWrapper } from './Components/SearchWrapper';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const Search: FunctionComponent = () => {
  const searchQuery = useAppSelector((state) => state.search.searchQuery);
  const searchType = useAppSelector((state) => state.search.searchType);
  const loading = useAppSelector((state) => state.search.isLoading);
  const pageCount = useAppSelector((state) => state.search.pageCount);
  const channelId = useAppSelector(
    (state) => state.channel.channelDetails?.channelId
  );
  const dispatch = useDispatch();
  const results = useAppSelector((state) => state.search.results);
  const [currentItems, setCurrentItems] = useState<Event[] | SearchRecording[]>(
    []
  );
  const [itemOffset, setItemOffset] = useState(0);
  const currentPageRef = useRef(1);
  const { ref, inView } = useInView({
    threshold: 0
  });
  const itemsPerPage = 21;
  const { isCreatorAppWebview, isEmbed, isBrowserEmbed } =
    useContext(UserAgentContext);
  const disableSearch = isCreatorAppWebview || isEmbed || isBrowserEmbed;

  const getResults = useCallback(
    (pageNumber: number) => {
      if (!channelId || !searchQuery) return;
      dispatch(
        fetchSearchResults(pageNumber, undefined, searchQuery, channelId)
      );
    },
    [searchQuery, channelId, dispatch]
  );

  useEffect(
    function fetchResultsOnSearchQueryChange() {
      setCurrentItems([]);
      getResults(1);
    },
    [searchQuery, getResults]
  );

  useEffect(
    function fetchResultsOnTypeChange() {
      setCurrentItems([]);
      getResults(1);
    },
    [searchType, getResults]
  );

  useEffect(
    function setCurrentSearchResults() {
      if (disableSearch) return;
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading results from ${itemOffset} to ${endOffset}`);
      setCurrentItems(results);
    },
    [itemOffset, itemsPerPage, results, disableSearch]
  );

  useEffect(
    function fetchRecordingsOnScroll() {
      if (inView && currentPageRef.current <= pageCount) {
        getResults(currentPageRef.current + 1);
        const newOffset = currentPageRef.current * itemsPerPage;
        setItemOffset(newOffset);
        currentPageRef.current += 1;
      }
    },
    [inView, getResults, itemsPerPage, pageCount]
  );

  if (disableSearch) {
    return null;
  }

  return (
    <SearchWrapper>
      <SearchBar />
      <SearchFilters />
      {currentItems.length > 0 ? (
        <ol className="list--reset tw-grid tw-grid-cols-12 tw-gap-4 md:tw-gap-8 lg:tw-gap-12">
          {currentItems.map((result, index) => {
            const lastElement = index === currentItems.length - 1;
            if (searchType === 'events') {
              return (
                <SearchResultEvent
                  key={result.id}
                  result={result as Event}
                  lastElement={lastElement}
                  ref={ref}
                />
              );
            } else {
              return (
                <SearchResultRecording
                  key={result.id}
                  result={result as SearchRecording}
                  lastElement={lastElement}
                  ref={ref}
                />
              );
            }
          })}
        </ol>
      ) : (
        !loading && <SearchEmpty />
      )}
      {loading && !inView && <Loader fillComponent={true} />}
      {loading && inView && (
        <div className="text--center mar-t mar-b">
          <Loader />
        </div>
      )}
    </SearchWrapper>
  );
};
