import React, { FunctionComponent, useContext } from 'react';
import { ThemeContext } from 'features/theme/theme';

interface SquareImageProps {
  artworkUrl: string | null;
  updatedTimestamp?: string;
  cssClasses?: string;
  squareCorners?: boolean;
}

export const SquareImage: FunctionComponent<SquareImageProps> = ({
  artworkUrl,
  updatedTimestamp,
  cssClasses,
  squareCorners
}) => {
  const { darkMode } = useContext(ThemeContext);

  const wrapperCSSClass = `tw-aspect-w-1 tw-aspect-h-1 tw-relative tw-mx-auto tw-relative tw-z-10 ${
    cssClasses ? cssClasses : ''
  }`;

  const cornerRadius = `${
    squareCorners
      ? ''
      : 'tw-rounded-3xl [.ios_&]:tw-rounded-lg tw-overflow-hidden'
  }`;

  if (!artworkUrl) {
    return null;
  }

  return (
    <div className={`${wrapperCSSClass} ${cornerRadius} tw-bg-white`}>
      <img
        src={`${artworkUrl}?ts=${updatedTimestamp}`}
        alt=""
        loading="lazy"
        className={`tw-object-cover tw-h-full tw-w-full ${
          darkMode
            ? 'tw-filter tw-brightness-50'
            : 'tw-filter tw-brightness-100'
        }`}
      />
    </div>
  );
};
