import React, { createContext, useState, useEffect } from 'react';
import { useOnceEffect } from 'hooks/useOnceEffect';
import {
  iosCreatorUARegex,
  iosListenerUARegex,
  androidCreatorUARegex,
  androidListenerUARegex,
  desktopUARegex,
  versionNumberRegex
} from 'lib/useragents';
import { compare } from 'compare-versions';

interface ContextProps {
  isEmbed: boolean;
  isBrowserEmbed: boolean;
  isiOSCreatorApp: boolean;
  isiOSCreatorAppVersion: (minVersion: string) => boolean;
  isAndroidCreatorApp: boolean;
  isAndroidCreatorAppVersion: (minVersion: string) => boolean;
  isiOSListenerApp: boolean;
  isiOSListenerAppVersion: (minVersion: string) => boolean;
  isAndroidListenerApp: boolean;
  isAndroidListenerAppVersion: (minVersion: string) => boolean;
  isNativeAppWebview: boolean;
  isDACreatorApp: boolean;
  isListenerAppWebview: boolean;
  isCreatorAppWebview: boolean;
  deviceClassName: string;
}

export const UserAgentContext = createContext<ContextProps>({
  isEmbed: false,
  isBrowserEmbed: false,
  isiOSCreatorApp: false,
  isiOSCreatorAppVersion: () => false,
  isAndroidCreatorApp: false,
  isAndroidCreatorAppVersion: () => false,
  isiOSListenerApp: false,
  isiOSListenerAppVersion: () => false,
  isAndroidListenerApp: false,
  isAndroidListenerAppVersion: () => false,
  isNativeAppWebview: false,
  isDACreatorApp: false,
  isListenerAppWebview: false,
  isCreatorAppWebview: false,
  deviceClassName: ''
});

const UserAgentProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [isEmbed, setEmbed] = useState(false);
  const [isBrowserEmbed, setBrowserEmbed] = useState(false);
  const [isiOSCreatorApp, setiOSCreatorApp] = useState(false);
  const [isAndroidCreatorApp, setAndroidCreatorApp] = useState(false);
  const [isiOSListenerApp, setiOSListenerApp] = useState(false);
  const [isAndroidListenerApp, setAndroidListenerApp] = useState(false);
  const [isDACreatorApp, setDACreatorApp] = useState(false);
  const [isNativeAppWebview, setNativeAppWebview] = useState(false);
  const [isListenerAppWebview, setListenerAppWebview] = useState(false);
  const [isCreatorAppWebview, setCreatorAppWebview] = useState(false);
  const [deviceClassName, setDeviceClassName] = useState('');

  useEffect(() => {
    const ua = navigator.userAgent;
    switch (true) {
      case iosCreatorUARegex.test(ua):
        setiOSCreatorApp(true);
        setDeviceClassName('ios ios-creator');
        break;
      case iosListenerUARegex.test(ua):
        setiOSListenerApp(true);
        setDeviceClassName('ios ios-listener');
        break;
      case androidCreatorUARegex.test(ua):
        setAndroidCreatorApp(true);
        setDeviceClassName('android android-creator');
        break;
      case androidListenerUARegex.test(ua):
        setAndroidListenerApp(true);
        setDeviceClassName('android android-listener');
        break;
      case desktopUARegex.test(ua):
        setDACreatorApp(true);
        setDeviceClassName('creator-da');
        break;
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    setEmbed(
      window === window.parent || searchParams.get('browser-embed')
        ? false
        : true
    );
    setBrowserEmbed(searchParams.get('browser-embed') ? true : false);
  }, [setEmbed, setBrowserEmbed]);

  useOnceEffect(() => {
    setDeviceClassName('embed');
  }, isEmbed);

  useOnceEffect(() => {
    setDeviceClassName('browser-embed');
  }, isBrowserEmbed);

  useOnceEffect(() => {
    setNativeAppWebview(true);
  }, isiOSCreatorApp || isAndroidCreatorApp || isDACreatorApp || isiOSListenerApp || isAndroidListenerApp);

  useOnceEffect(() => {
    setListenerAppWebview(true);
    setDeviceClassName(deviceClassName + ' app-listener');
  }, isiOSListenerApp || isAndroidListenerApp);

  useOnceEffect(() => {
    setCreatorAppWebview(true);
    setDeviceClassName(deviceClassName + ' app-creator');
  }, isiOSCreatorApp || isAndroidCreatorApp || isDACreatorApp);

  const testRegex = (uaregex: RegExp, minVersion: string): boolean => {
    const ua = navigator.userAgent;
    const matcher = new RegExp(uaregex);
    const versionNumber = ua.match(versionNumberRegex);
    if (versionNumber && matcher.test(ua)) {
      return compare(versionNumber[1], minVersion, '>=');
    } else {
      return false;
    }
  };

  const isiOSListenerAppVersion = (minVersion: string) => {
    return testRegex(iosListenerUARegex, minVersion);
  };

  const isAndroidListenerAppVersion = (minVersion: string) => {
    return testRegex(androidListenerUARegex, minVersion);
  };

  const isiOSCreatorAppVersion = (minVersion: string) => {
    return testRegex(iosCreatorUARegex, minVersion);
  };

  const isAndroidCreatorAppVersion = (minVersion: string) => {
    return testRegex(androidCreatorUARegex, minVersion);
  };

  return (
    <UserAgentContext.Provider
      value={{
        isEmbed,
        isBrowserEmbed,
        isiOSCreatorApp,
        isiOSCreatorAppVersion,
        isAndroidCreatorApp,
        isAndroidCreatorAppVersion,
        isiOSListenerApp,
        isiOSListenerAppVersion,
        isAndroidListenerApp,
        isAndroidListenerAppVersion,
        isNativeAppWebview,
        isDACreatorApp,
        isListenerAppWebview,
        isCreatorAppWebview,
        deviceClassName
      }}
    >
      {children}
    </UserAgentContext.Provider>
  );
};

export default UserAgentProvider;
