import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setEventMinimised,
  setNativeEventStarted,
  setNativeEventStopped
} from 'features/events/eventsSlice';
import { QWebChannel } from 'qwebchannel';
import { setNativeAudioStatus } from 'features/audio/audioSlice';
import {
  accesscodeValidateSuccess,
  fetchChannelView
} from 'features/channel/channelSlice';
import { logoutListenerApp } from 'features/current-user/thunks';
import { fetchCurrentUser } from 'features/current-user/thunks';
import qs from 'qs';
import { NavigateFunction } from 'react-router-dom';
import { useSubdomain } from 'hooks/useSubdomain';
import { setCrowdVisible } from 'features/crowd/crowdSlice';

export function useNativeEvents() {
  const w = window as any;
  w.reactBridge = w.reactBridge || {};
  w.webviewBridge = w.webviewBridge || {};
  const dispatch = useDispatch();
  const username = useSubdomain();

  const postMessage = useCallback(
    (message: any) => {
      // iOS JS communication
      if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.javascriptHandler
      ) {
        console.log('[javascriptHandler] iOS: ', message);
        w.webkit.messageHandlers.javascriptHandler.postMessage(message);
      }
      // Android JS communication
      if (w.javascriptHandler) {
        console.log('[javascriptHandler] Android: ', message);
        // addJavascriptInterface() only works with primitive types and Strings
        // You cannot pass arbitrary Javascript objects.
        if (message instanceof Object) {
          w.javascriptHandler.postMessage(JSON.stringify(message));
        } else {
          w.javascriptHandler.postMessage(message);
        }
      }
      // DA JS communication
      if (w.webchannel) {
        console.log('[javascriptHandler] DA: ', message);
        w.webchannel.postMessage(message);
      }
    },
    [w]
  );

  const dynamicSizeUpdatesEvent = useCallback(() => {
    w.dynamicSizeUpdates = (fontSize: number, inputSize: number) => {
      console.log('[javascriptHandler]: ', 'Setting dynamic type updates');
      const fontPixelSize = fontSize + 'px';
      const inputPixelSize = inputSize + 'px';
      document.documentElement.style.setProperty('font-size', fontPixelSize);
      document.documentElement.style.setProperty(
        '--input-height',
        inputPixelSize
      );
    };
  }, [w]);

  const eventWindowEvents = useCallback(
    (pathname: string, navigate: NavigateFunction) => {
      // Creator app events
      w.reactBridge.nativeEventStarted = () => {
        console.log('[javascriptHandler]: ', 'Native event started');
        dispatch(setNativeEventStopped(false));
        dispatch(setNativeEventStarted(true));
      };
      w.reactBridge.nativeEventStopped = () => {
        console.log('[javascriptHandler]: ', 'Native event stopped');
        dispatch(setNativeEventStarted(false));
        dispatch(setNativeEventStopped(true));
      };
      w.reactBridge.nativeEventError = () => {
        console.log('[javascriptHandler]: ', 'Native event error');
        dispatch(setNativeEventStarted(false));
      };
      w.reactBridge.forceRefresh = () => {
        console.log('[javascriptHandler]: ', 'Native event force refresh');
        dispatch(setNativeEventStarted(false));
        navigate(pathname, {});
      };
      w.reactBridge.playerExpanded = () => {
        console.log('[javascriptHandler]: ', 'Native event player expanded');
        dispatch(setEventMinimised(false));
      };
      w.reactBridge.playerMinimized = () => {
        console.log('[javascriptHandler]: ', 'Native event player minimised');
        dispatch(setEventMinimised(true));
      };
      w.reactBridge.toggleCrowd = (isCrowdVisible: boolean) => {
        console.log(
          '[javascriptHandler]: ',
          `Native crowd visible ${isCrowdVisible}`
        );
        dispatch(setCrowdVisible(isCrowdVisible));
      };

      // Listener app events
      w.webviewBridge.nativeAudioStatus = (status: string) => {
        console.log('[javascriptHandler]: ', `Native audio status ${status}`);
        dispatch(setNativeAudioStatus(status));
      };
      w.webviewBridge.loginUser = () => {
        console.log('[javascriptHandler]: ', `Native event logged in user`);
        dispatch(fetchCurrentUser());
      };
      w.webviewBridge.logoutUser = () => {
        console.log('[javascriptHandler]: ', `Native event logged out user`);
        dispatch(logoutListenerApp());
      };
      w.webviewBridge.accesscodeValidateSuccess = () => {
        console.log(
          '[javascriptHandler]: ',
          `Native event access code success`
        );
        dispatch(accesscodeValidateSuccess());
        dispatch(fetchChannelView(username));
      };
    },
    [w, dispatch, username]
  );

  const iframeMessageHandler = useCallback(() => {
    const handleMessage = (message: MessageEvent) => {
      console.log('[MessageEvent]:', message);
      if (
        message.data &&
        message.data.sender == 'browserBroadcasting' &&
        message.data.value
      ) {
        if (message.data.value === 'start') {
          console.log('[Browser broadcasting] - Start Message');
          dispatch(setNativeEventStopped(false));
          dispatch(setNativeEventStarted(true));
        } else if (message.data.value === 'stop') {
          console.log('[Browser broadcasting] - Stop Message');
          dispatch(setNativeEventStarted(false));
          dispatch(setNativeEventStopped(true));
        }
      }
    };
    w.addEventListener('message', handleMessage);
    return () => {
      console.log('[MessageEvent] - Removing event listener');
      window.removeEventListener('message', handleMessage);
    };
  }, [w, dispatch]);

  const qWebChannel = useCallback(() => {
    if (w.qt && w.qt.webChannelTransport) {
      new QWebChannel(w.qt.webChannelTransport, function (channel) {
        w.webchannel = channel.objects.webchannel;
      });
    }
  }, [w]);

  const nativeUrlSchemeLink = (
    schema: string,
    name: string,
    params: {
      url: string;
      channel_url?: string;
      channel_name?: string;
      channel_theme_color?: string;
      channel_logo_url?: string | null;
      name?: string;
      theme_color?: string;
      logo_url?: string | null;
      action?: string;
    }
  ) => {
    return `${schema}://${name}?${qs.stringify(params)}`;
  };

  return {
    postMessage,
    dynamicSizeUpdatesEvent,
    eventWindowEvents,
    qWebChannel,
    nativeUrlSchemeLink,
    iframeMessageHandler
  };
}
