export async function apiV3SendCommentReactionCreated(
  commentId: string,
  reactionType: string,
  socketId: string
): Promise<null> {
  const formData = new FormData();

  formData.set('comment_reaction[reaction_type]', reactionType);
  formData.set('comment_reaction[socket_id]', socketId);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/comments/${commentId}/comment_reactions`,
    {
      method: 'post',
      credentials: 'include',

      body: formData
    }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export async function apiV3SendCommentReactionRemoved(
  commentId: string,
  reactionType: string,
  socketId: string
): Promise<null> {
  const formData = new FormData();

  formData.set('comment_reaction[reaction_type]', reactionType);
  formData.set('comment_reaction[socket_id]', socketId);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/comments/${commentId}/comment_reactions`,
    {
      method: 'delete',
      credentials: 'include',

      body: formData
    }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}
