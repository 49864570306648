import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { EmojiClickData } from 'emoji-picker-react';
import EmojiPickerButton, { EmojiPickerHandle } from 'components/EmojiPicker';
import { handleReaction } from 'features/chat/chatSlice';
import { Reaction } from 'features/chat/chatSlice';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { useCurrentUser } from 'features/current-user/hooks';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useAppSelector } from 'hooks/redux';
import { useDispatch } from 'react-redux';

interface CommentReactionPickerProps {
  commentId: string;
  parentIsHovered?: boolean;
  pickerOpened?: (opened: boolean) => void;
}

export const CommentReactionPicker: FunctionComponent<
  CommentReactionPickerProps
> = (props) => {
  const emojiPickerRef = useRef<EmojiPickerHandle>(null);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const socketId = useAppSelector((state) => state.hub.socketId);
  const { isListenerAppWebview, isEmbed } = useContext(UserAgentContext);
  const commentReactionsEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.commentReactionsEnabled
  );

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    if (currentUser) {
      const reaction: Reaction = {
        authorId: currentUser?.id,
        commentId: props.commentId,
        reactionType: emojiObject.emoji,
        // TODO: Socket ID shouldn't be part of this interface, we should pass it separately in actions
        // that send events to API
        socketId: socketId
      };
      dispatch(handleReaction(reaction));
    } else {
      if (isListenerAppWebview) {
        window.location.href = `mixlr://auth?url=${process.env.REACT_APP_WEBSITE_ENDPOINT}/signin`;
      } else {
        dispatch(showAuthenticationEmailPromptForm(`To react to comments`));
      }
    }
    emojiPickerRef.current?.closeEmojiPicker();
    if (props.pickerOpened) {
      props.pickerOpened(false);
    }
  };

  useEffect(
    function unhoverCommentHide() {
      if (!props.parentIsHovered) {
        emojiPickerRef.current?.closeEmojiPicker();
      }
    },
    [props.parentIsHovered]
  );

  if (isEmbed || !commentReactionsEnabled) {
    return null;
  }

  return (
    <EmojiPickerButton
      ref={emojiPickerRef}
      onEmojiClick={onEmojiClick}
      pickerOpened={props.pickerOpened}
      reactionsDefaultOpen={true}
      buttonLabel="+"
    />
  );
};
