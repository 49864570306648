import React, {
  FunctionComponent,
  useContext,
  useState,
  useRef,
  memo
} from 'react';
import giphyStyles from '../EmojiPicker/styles.module.scss';
import { Icon } from 'components/Icons';
import { useOnClickOutside } from 'usehooks-ts';
import { ThemeContext } from 'features/theme/theme';
import { useAppSelector } from 'hooks/redux';
import GifPicker, { TenorImage, Theme, ContentFilter } from 'gif-picker-react';
import { usePopper } from 'react-popper';

interface GiphyPickerProps {
  onGifClick: (gif: TenorImage) => void;
}

export const GiphyPicker: FunctionComponent<GiphyPickerProps> = (props) => {
  const [pickerButton, setPickerButton] = useState<HTMLButtonElement | null>(
    null
  );
  const [pickerGiphy, setPickerGiphy] = useState<HTMLDivElement | null>(null);
  const giphyPickerRef = useRef<HTMLDivElement>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);
  const { darkMode } = useContext(ThemeContext);
  const gifsInChatEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.gifsInChatEnabled
  );
  const { styles, attributes } = usePopper(pickerButton, pickerGiphy, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
          rootBoundary: 'document'
        }
      }
    ],
    strategy: 'fixed'
  });

  const handleGifPickerClickOutside = () => {
    togglePicker(false);
  };

  useOnClickOutside(giphyPickerRef, handleGifPickerClickOutside);

  const onGifClick = (gif: TenorImage) => {
    props.onGifClick(gif);
    togglePicker(false);
  };

  if (!gifsInChatEnabled) {
    return null;
  }

  return (
    <div ref={giphyPickerRef}>
      <button
        ref={setPickerButton}
        onClick={() => togglePicker(!pickerOpen)}
        className={giphyStyles['emoji-picker__button']}
        type="button"
      >
        <Icon icon={'gif'} hidden={true} label={'Choose gif'} />
      </button>
      {pickerOpen && (
        <div
          className={`tw-shadow-xl tw-rounded-xl tw-overflow-hidden tw-z-50 tw-max-w-[280px] w-full`}
          ref={setPickerGiphy}
          style={styles.popper}
          {...attributes.popper}
        >
          <GifPicker
            tenorApiKey={`${process.env.REACT_APP_TENOR_API_KEY}`}
            autoFocusSearch={false}
            onGifClick={onGifClick}
            theme={darkMode ? Theme.DARK : Theme.LIGHT}
            contentFilter={ContentFilter.MEDIUM}
            height={260}
            width={'100%'}
          />
        </div>
      )}
    </div>
  );
};

export default memo(GiphyPicker);
