import React, { FunctionComponent, useContext } from 'react';
import {
  handleReaction,
  ReactionState,
  Reaction
} from 'features/chat/chatSlice';
import { useCurrentUser } from 'features/current-user/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useAppSelector } from 'hooks/redux';
import { formatNumber } from 'utilities/formatters';
import { CommentReactionPicker } from './CommentReactionPicker';

interface CommentReactionsProps {
  commentId: string;
  reactions: ReactionState[];
}

export const CommentReactions: FunctionComponent<CommentReactionsProps> = ({
  commentId,
  reactions
}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const socketId = useSelector((state: RootState) => state.hub.socketId);
  const { isListenerAppWebview, isEmbed } = useContext(UserAgentContext);
  const commentReactionsEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.commentReactionsEnabled
  );

  const onReactionClick = (reactionType: string) => {
    if (currentUser) {
      const reaction: Reaction = {
        authorId: currentUser?.id,
        commentId: commentId,
        reactionType: reactionType,
        // TODO: Socket ID shouldn't be part of this interface, we should pass it separately in actions
        // that send events to API
        socketId: socketId
      };
      dispatch(handleReaction(reaction));
    } else {
      if (isListenerAppWebview) {
        window.location.href = `mixlr://auth?url=${process.env.REACT_APP_WEBSITE_ENDPOINT}/signin`;
      } else {
        dispatch(showAuthenticationEmailPromptForm(`To react to comments`));
      }
    }
  };

  const renderBackgroundColor = (reaction: ReactionState): string => {
    if (currentUser?.id && reaction.authorIds?.includes(currentUser.id)) {
      return 'tw-bg-theme-light';
    }
    return 'tw-bg-line-color';
  };

  if (isEmbed || !commentReactionsEnabled) {
    return null;
  }

  if (!reactions?.length) {
    return null;
  }

  return (
    <div className="tw-ml-[28px] tw-relative tw-flex tw-flex-wrap tw-mt-1 tw-mb-2 tw-space-x-1 tw-space-x-reverse tw-items-center tw-relative">
      {reactions.map((reactionState) => (
        <button
          onClick={() => onReactionClick(reactionState.reactionType)}
          key={reactionState.reactionType}
          className={`${renderBackgroundColor(
            reactionState
          )} first:tw-mr-1 tw-mt-1 tw-border-0 tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-px-2 tw-text-xl [.ios_&]:tw-text-sm [.ios_&]:tw-py-1 tw-cursor-pointer tw-whitespace-nowrap`}
        >
          {reactionState.reactionType}{' '}
          {reactionState.count && formatNumber(reactionState.count)}
        </button>
      ))}
      <div className="tw-mt-1 [&_.icon-button]:tw-text-xl [&_.icon-button][.ios_&]:tw-text-sm [&_.icon-button][.ios_&]:tw-py-1 [&_.icon-button]:tw-px-2 [&_svg]:tw-h-[16px] [&_svg]:tw-w-[16px]">
        <CommentReactionPicker commentId={commentId} />
      </div>
    </div>
  );
};
