import { AppThunk } from 'store';
import { setHubBroadcastConnected } from './hubSlice';
import { updateEvent } from 'features/events/eventsSlice';
import {
  HubEventMessage,
  SocketMessage,
  HubListenerCount,
  HubActionCreated,
  HubBroadcastStart
} from 'features/hub/hub';
import { Broadcast, updateBroadcast } from 'features/broadcast/broadcastsSlice';
import { addHeartMessage } from 'features/chat/chatSlice';
import { broadcastSelector } from 'features/broadcast/selectors';
import { DateTime } from 'luxon';
import { fetchAudioAbort } from 'features/audio/audioThunks';

export const handleBroadcastFinished =
  (
    message: SocketMessage,
    disconnectBroadcast?: (_: string) => void
  ): AppThunk =>
  async (dispatch) => {
    const uid = message.channel.split(';')[2];

    const broadcastUpdate = {
      uid: uid,
      isLive: false,
      finished: true
    };

    dispatch(updateBroadcast(broadcastUpdate));
    fetchAudioAbort();

    if (disconnectBroadcast) {
      disconnectBroadcast(uid);
    } else {
      dispatch(setHubBroadcastConnected(false));
    }
  };

export const handleEventStopped =
  (message: HubEventMessage): AppThunk =>
  async (dispatch) => {
    dispatch(
      updateEvent({
        id: message.event.id.toString(),
        active: false,
        endedAt: DateTime.now().toISO(),
        lastHubEventType: null
      })
    );
  };

export const handleListenerCountMessage =
  (message: SocketMessage): AppThunk =>
  async (dispatch) => {
    const uid = message.channel.split(';')[2];

    const listenerMessage = message.data as HubListenerCount;

    const broadcastUpdate = {
      uid: uid,
      listenerCount: listenerMessage.count
    };

    dispatch(updateBroadcast(broadcastUpdate));
  };

export const handleActionCreated =
  (message: SocketMessage): AppThunk =>
  async (dispatch, getState) => {
    const actionCreatedMessage = message.data as HubActionCreated;

    const broadcast = broadcastSelector(
      getState(),
      actionCreatedMessage.broadcast_uid
    );

    if (!broadcast) {
      return;
    }

    const heartCount = Math.max(
      (broadcast.heartCount || 0) + 1,
      actionCreatedMessage.total
    );

    const broadcastUpdate = {
      uid: broadcast.uid,
      heartCount: heartCount
    };

    const heartMessage = {
      display_name: actionCreatedMessage.username,
      user_id: actionCreatedMessage.user_id,
      created_at: actionCreatedMessage.created_at
    };

    dispatch(updateBroadcast(broadcastUpdate));
    dispatch(addHeartMessage(heartMessage));
  };

export const finishFallbackBroadcast =
  (
    hubBroadcast: HubBroadcastStart,
    currentBroadcast: Broadcast | null,
    disconnectBroadcast?: (_: string) => void
  ): AppThunk =>
  async (dispatch) => {
    if (hubBroadcast.broadcast.uid === currentBroadcast?.uid) return;
    if (!currentBroadcast?.fallback || currentBroadcast?.finished) return;

    console.log(
      '[hub] Forcing event:stopped / broadcast:finished on Fallback event',
      currentBroadcast
    );

    dispatch(
      handleBroadcastFinished(
        {
          channel: `${process.env.REACT_APP_HUB_ENV};broadcast;${currentBroadcast.uid}`,
          data: {},
          type: 'finished'
        },
        disconnectBroadcast
      )
    );

    if (currentBroadcast.eventId) {
      dispatch(
        updateEvent({
          id: currentBroadcast.eventId,
          active: false,
          endedAt: DateTime.now().toISO(),
          lastHubEventType: null
        })
      );
    }
  };
