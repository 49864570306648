import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  isMobileOnly,
  isTablet,
  isDesktop,
  browserName,
  browserVersion,
  isIOS
} from 'react-device-detect';
import { useLocation, matchPath } from 'react-router-dom';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ThemeContext } from 'features/theme/theme';
import { useAppSelector } from 'hooks/redux';
import classNames from 'classnames';
import reactManifest from 'react-manifest';
import { displayModeStandalone } from 'utilities/displayModeStandalone';

export const HelmetTags: FunctionComponent = () => {
  const { deviceClassName, isiOSListenerApp, isiOSCreatorApp } =
    useContext(UserAgentContext);
  const { theme, themeContrast, themeColor, darkMode } =
    useContext(ThemeContext);
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const isRecordingPath = matchPath('/recordings/:id', pathname);
  const channel = useAppSelector((state) => state.channel.channelDetails);

  useEffect(() => {
    const manifestDetails = {
      name: channel?.username,
      short_name: channel?.username,
      start_url: '/',
      display: 'standalone',
      orientation: 'portrait',
      theme_color: channel?.themeColor,
      background_color: '#ffffff',
      icons: [
        {
          src: channel?.channelLogo.favicon.x192,
          sizes: '192x192'
        },
        {
          src: channel?.channelLogo.favicon.x512,
          sizes: '512x512'
        }
      ]
    };
    reactManifest.update(manifestDetails, '#manifest-placeholder');
  }, [channel]);

  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400..700;1,400..700&display=swap"
        rel="stylesheet"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={channel?.channelLogo.favicon.x32 || ''}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={channel?.channelLogo.favicon.x16 || ''}
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="180x180"
        href={channel?.channelLogo.favicon.x180 || ''}
      />
      <meta name="msapplication-TileColor" content={themeColor} />
      <meta name="theme-color" content={themeColor} />
      {isIOS || isiOSListenerApp || isiOSCreatorApp ? (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
      ) : (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
      )}
      <html
        className={classNames(deviceClassName, {
          'dark-mode': darkMode,
          standalone: displayModeStandalone()
        })}
        lang={navigator.language}
      />
      <body
        className={classNames(
          theme,
          themeContrast,
          `${browserName.toLowerCase()}-${browserVersion}`,
          {
            mobile: isMobileOnly,
            tablet: isTablet,
            desktop: isDesktop,
            'body-full-screen': isEventPath || isRecordingPath,
            'body-event': isEventPath,
            'body-recording': isRecordingPath,
            'dark-mode': darkMode
          }
        )}
      />
    </Helmet>
  );
};
