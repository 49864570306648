import { DateTime } from 'luxon';
import React, { FunctionComponent, memo } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

export interface AutoplayCountdownProps {
  startedAt: DateTime;
  millisecondsUntilAutoplay: number;
}

const calculateSecondsLeft = (
  startedAt: DateTime,
  millisecondsUntilAutoplay: number
): number => {
  const finishTime = startedAt.plus({
    milliseconds: millisecondsUntilAutoplay
  });

  return Math.round(finishTime.diff(DateTime.now()).shiftTo('seconds').seconds);
};

export const AutoplayCountdown: FunctionComponent<AutoplayCountdownProps> = (
  props
) => {
  const secondsLeft = calculateSecondsLeft(
    props.startedAt,
    props.millisecondsUntilAutoplay
  );
  const themeColor = getComputedStyle(document.body).getPropertyValue(
    '--color__primary'
  );

  return (
    <CountdownCircleTimer
      isPlaying
      size={24}
      updateInterval={1}
      strokeWidth={2}
      duration={secondsLeft}
      trailColor="#ffffff"
      colors={`rgb(${themeColor})`}
      initialRemainingTime={secondsLeft}
    >
      {({ remainingTime }) => remainingTime}
    </CountdownCircleTimer>
  );
};

export default memo(AutoplayCountdown);
