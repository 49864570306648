import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { AnimatePresence } from 'framer-motion';
import ChatComment from 'features/chat/components/ChatComment';
import { ChatMessageModel } from 'features/chat/ChatMessageModel';
import { useInView } from 'react-intersection-observer';
import { fetchChatHistory, setLoadingOnScroll } from '../chatSlice';
import { useDispatch } from 'react-redux';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useAppSelector } from 'hooks/redux';

interface Props {
  messages: ChatMessageModel[];
}

export const ChatComments: FunctionComponent<Props> = ({ messages }) => {
  const currentPageRef = useRef(0);
  const { ref, inView } = useInView({
    threshold: 0
  });
  const dispatch = useDispatch();
  const { isEmbed } = useContext(UserAgentContext);
  const channelId = useAppSelector(
    (state) => state.channel.channelDetails?.ownerId
  );
  const loadedAll = useAppSelector((state) => state.chat.loadedAll);

  useEffect(
    function fetchChatCommentsOnScroll() {
      if (loadedAll) return;
      if (inView && channelId && isEmbed) {
        const newOffset = currentPageRef.current * 250;
        dispatch(setLoadingOnScroll(true));
        dispatch(fetchChatHistory(channelId, isEmbed, newOffset));
        currentPageRef.current += 1;
      }
    },
    [inView, dispatch, channelId, isEmbed, loadedAll]
  );

  return (
    <ul className={styles['chat__list']}>
      <AnimatePresence initial={false}>
        {messages.map((comment, index) => {
          const lastElement = index === 0;
          return (
            <ChatComment
              key={comment.id}
              lastElement={lastElement}
              ref={ref}
              id={comment.id}
              comment={comment.content}
              user={comment.display_name}
              userId={comment.user_id}
              date={comment.parsedCreatedAt}
              img={comment.profile_image_url}
              type={comment.type}
              reactions={comment.reactions}
              heartScale={comment.heartScale}
            />
          );
        })}
      </AnimatePresence>
    </ul>
  );
};
